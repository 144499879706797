<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
      <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
      <span class="text-dark fw-bold-400 size14">Ads</span>
    </header-page>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <input v-model="filter.search" type="text" class="form-control" placeholder="Search...">
        </div>
      </div>
      <div class="col-md-6" />
      <div class="col-md-2">
        <button v-b-modal.form-modal class="btn btn-primary btn-block" @click="cleanUpForm()"
          v-if="checkPermission('add ads')">
          Create
        </button>
      </div>
    </div>

    <b-card-code no-body>
      <div class="table-responsive-sm">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Cabang</th>
              <th>URL</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="5" class="text-center">
                <b-spinner label="Loading..." variant="primary" />
              </td>
            </tr>
            <tr v-for="item in result.data" v-if="!isLoading" :key="item">
              <td><img :src="item.image_url" style="max-width: 50px; margin-right: 10px;"></td>
              <td>{{ item && item.title || '-' }}</td>
              <td>{{ item && item.merchant ? item.merchant.name : 'All Cabang' }}</td>
              <td>{{ item.meta && item.meta.url || '-' }}</td>
              <td class="text-right">
                <div class="btn-group" role="group" aria-label="Basic example">

                  <button v-if="checkPermission('update ads')" class="btn btn-info btn-sm" style="margin-left: 2px;"
                    data-toggle="tooltip" data-placement="top" title="Edit" @click="editItem(item)">
                    <feather-icon icon="EditIcon" />
                  </button>

                  <button v-if="checkPermission('delete ads')" class="btn btn-danger btn-sm" style="margin-left: 2px;"
                    data-toggle="tooltip" data-placement="top" title="Delete" @click="deleteItem(item.id)">
                    <feather-icon icon="Trash2Icon" />
                  </button>

                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

    <!-- Add/Edit Modal -->
    <b-modal id="form-modal" size="lg" :title="editId != null ? 'Edit' : 'Add'">

      <div class="form-group">
        <label for="">Image:</label><br>
        <input id="file" ref="file" type="file" accept="image/*" @change="changeImage">

        <div class="alert alert-info p-1 mt-1">
          <strong>Catatan</strong>
          <ul>
            <li>Maksimal ukuran file adalah 1MB</li>
          </ul>
        </div>
      </div>

      <div class="form-group custom__form--input">
        <label for="">Title:</label>
        <input v-model="formPayload.title" type="text" class="form-control custom__input"
          :class="validations.hasOwnProperty('title') && validations.title.length > 0 ? 'is-invalid' : ''"
          placeholder="Title...">
        <small v-if="validations.hasOwnProperty('title') && validations.name.length > 0" class="text-danger">
          {{ validations.title.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="branch_uuid">Cabang:</label>
        <v-select id="branch_uuid" v-model="formPayload.branch_uuid" :options="formData.merchants"
          :reduce="item => item.uuid" label="name" placeholder="Type to search Cabang..." @search="onSearchMerchant">
          <template slot="no-options">
            Type here to search item...
          </template>
        </v-select>
        <small>Keep it empty if you want to show image on all Cabang</small>
      </div>

      <div class="form-group custom__form--input">
        <label for="">URL:</label>
        <input v-model="formPayload.url" type="text" class="form-control custom__input"
          :class="validations.hasOwnProperty('url') && validations.url.length > 0 ? 'is-invalid' : ''"
          placeholder="URL...">
        <small v-if="validations.hasOwnProperty('url') && validations.url.length > 0" class="text-danger">
          {{ validations.url.join(', ') }}
        </small>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button v-if="editId == null" variant="success" :disabled="isLoading" @click="createItem()">
          Save Item
        </b-button>
        <b-button v-else variant="success" :disabled="isLoading" @click="updateItem()">
          Save Item
        </b-button>
      </template>
    </b-modal>
    <!-- Add/Edit Modal -->

  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
  BFormGroup, BTabs, BTab, BNavItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'
import HeaderPage from '@/components/HeaderPage.vue'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Ads Management'
  },
  components: {
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BNavItem,
    BSpinner,
    vSelect,
    HeaderPage
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: Object,
      isLoading: false,
      isLoadingImport: false,
      isLoadingExort: false,
      formPayload: {
        title: '',
        image: '',
        branch_uuid: '',
        type: 'ads',
        url: ''
      },
      detail: null,
      editId: null,
      filter: {
        search: '',
      },
      importPayload: {
        file: null,
      },
      formData: {
        merchants: [],
      },
      validations: {},
    }
  },
  created() {
    this.getData()
  },
  mounted() {

  },
  methods: {
    upPosition(item) {
      this.$http.post(`/image/${item.id}/up-position`)
        .then(response => {
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    downPosition(item) {
      this.$http.post(`/image/${item.id}/down-position`)
        .then(response => {
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    onSearchMerchant(search, loading) {
      if (search.length) {
        loading(true)
        this.searchMerchant(loading, search, this)
      }
    },
    searchMerchant: _.debounce((loading, search, vm) => {
      vm.$http.get(`/merchantBranch?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.merchants = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    changeImage(event) {
      const target = event.target
      if (target.files && target.files[0]) {
        const maxAllowedSize = 1 * 1024 * 1024;
        if (target.files[0].size > maxAllowedSize) {
          errorNotification(this, 'Oops!', 'Ukuran file terlalu besar!')
          target.value = ''
          this.formPayload.image = ''
        } else {
          this.formPayload.image = target.files[0]
        }
      }
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        title: '',
        image: '',
        branch_uuid: '',
        type: 'ads'
      }
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage

      this.$http.get('/image', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    createItem() {
      const form = this.preparePayload()

      this.$http.post('/image', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          console.log(response, 'HIHIHI');
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          console.log(error.response, 'EHEHEHE');
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      this.$http.post(`/image/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    preparePayload() {
      const form = new FormData()

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          // Handle if this.formPayload[key] is array
          form.append(key, this.formPayload[key])
        }
        if (typeof this.formPayload[key] == 'object') {
          form.append(key, this.formPayload[key].uuid)
        }
      }

      return form
    },
    editItem(item) {
      const branchUuid = {
        uuid: item?.branch?.uuid,
        name: item?.branch?.name,
      }
      const isBranchUuidAvailable = item?.branch ? branchUuid : null

      this.editId = item.id
      this.formPayload = {
        title: item.title,
        image_url: item.image_url,
        branch_uuid: isBranchUuidAvailable,
        url: item?.meta?.url,
        type: 'ads'
      }

      this.$bvModal.show('form-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/image/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Item successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
